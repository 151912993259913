import * as LUI from './styled'
import Header from "./components/Header";
import MainContent from "./components/MainContent";
import Services from "./components/Services";
import AboutUs from "./components/AboutUs";
import Secrets from "./components/Secrets";
import SecretsList from "./components/SecretsList";
import Text from "./components/Text";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import {useEffect, useState} from "react";

const Main = () => {
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768)
    const [isTable, setIsTable] = useState<boolean>(window.innerWidth <= 1024)
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    useEffect(() => {
        const handleResize = () => {
            setIsTable(window.innerWidth <= 1024);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <div>
            <Header isMobile={isMobile} isTable={isTable}/>
            <MainContent/>
            <Services isMobile={isMobile}/>
            <AboutUs/>
            <Secrets/>
            <SecretsList/>
            <Text/>
            <Contact/>
            <Footer/>
        </div>
    )
}

export default Main
import * as LUI from './styled'
import {ABOUT_US_ID} from "../../../../utils/constants";

const AboutUs = () => {
    return (
        <LUI.Container id={ABOUT_US_ID}>
            <img src={'./aboutUsImage.png'} alt={'about us'}/>
            <div>
                <h3>About us</h3>
                <p>Air Sparepart Solution stands as a premier provider of aviation parts and components, extending its services to esteemed clients across America, Asia, and the vibrant aviation hub of Dubai. Our steadfast commitment to excellence, coupled with our expansive global reach, positions us as specialists in delivering premium-grade products and unparalleled service to the aviation industry. At Air Sparepart Solution, integrity and reliability serve as the cornerstone of our operations. Our dedicated team is unwaveringly devoted to upholding the highest standards of quality, safety, and compliance, ensuring that our esteemed customers entrust us with their most critical aviation requirements. From esteemed airlines to meticulous maintenance facilities, our clientele relies on us to furnish dependable solutions that uphold the safety and efficiency of their airborne fleets.</p>
            </div>
        </LUI.Container>
    )
}

export default AboutUs
import * as LUI from './styled'

const Text = () => {
    return (
        <LUI.Container>
            <p>In the bustling aviation hub of Dubai, where commerce converges amidst a backdrop of innovation and ambition, the consolidation of aircraft parts assumes a unique significance. Amidst the dynamic landscape of global trade, navigating the intricacies of logistics and compliance in this vibrant metropolis requires a blend of expertise and precision.
                As Air Sparepart Solution operates within this dynamic milieu, our commitment to excellence is amplified. Dubai serves as a pivotal nexus in our global network, where we leverage our extensive experience and resources to orchestrate seamless transactions and uphold the highest standards of quality and reliability.</p>
            <p>In Dubai, the consolidation of aircraft parts epitomizes a fusion of tradition and modernity, where ancient trade routes intersect with cutting-edge logistics infrastructure. By adhering to stringent regulations, selecting trusted partners, and maintaining meticulous oversight, Air Sparepart Solution ensures the smooth and secure delivery of cargo, contributing to the sustained momentum of the aviation industry in this vibrant city.
                Discover the secrets to successful aircraft parts consolidation in Dubai with Air Sparepart Solution, and unlock new opportunities for growth and prosperity in the dynamic world of aviation.</p>
        </LUI.Container>
    )
}

export default Text

import {backendUrl} from "../utils/url";
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

type TMailRequest = {
    email : string
    message : string
    name : string
}

export const MailApi = createApi({
    reducerPath: 'Mail',
    baseQuery: fetchBaseQuery({
        baseUrl: backendUrl
    }),
    endpoints: (build) => ({
        sendMail: build.mutation<void, TMailRequest>({
            query: (body) => ({
                method: 'POST',
                body,
                url : '/send-mail'
            })
        }),
    }),
})

export const { useSendMailMutation } = MailApi

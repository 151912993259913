import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    img {
        width: 50%;
        margin-top: auto;
        height: fit-content;
    }
    div {
        width: 50%;
        display: flex;
        padding: 32px 88px 32px 32px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-bottom: auto;
        gap: 16px;
        flex: 1 0 0;
        align-self: stretch;
        h3 {
            color:#000;
            text-align: center;
            font-size: 30px;
            font-weight: 600;
            line-height: 38px;
            text-transform: uppercase;
        }
        p {
            color:  #32334D;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
        }
    }
    @media (max-width: 1024px) {
        padding: 0 164px;
        flex-direction: column;
        img {
            width: 100%;
        }
        div {
            padding: 24px 0 32px 0;
            width: 100%;
            h3 {
                font-size: 26px; 
            }
            p {
                font-size: 14px;
            }
        }
    }
    @media (max-width: 768px) {
        padding: 0;
        div {
            padding: 24px 20px 32px 20px;
        }
    }
`
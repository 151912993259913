import * as LUI from './styled'
import {servicesElements} from "./constants";
import {SERVICES_ID} from "../../../../utils/constants";

const Services = (props : {isMobile : boolean}) => {
    const {isMobile} = props
    return (
        <LUI.Container id={SERVICES_ID}>
            <LUI.TopBlock>
                <LUI.LeftBlock>
                    <h2>Best Services for best customers</h2>
                    <p>Ensuring aircraft operates smoothly with quality parts and dedicated support</p>
                    <img src={isMobile ?'./servicesSecondImage.png' : './servicesFirstImage.png'} alt={'pic1'}/>
                </LUI.LeftBlock>
                <LUI.RightBlock>
                    <img src={isMobile ?'./servicesFirstImage.png' : './servicesSecondImage.png'} alt={'pic2'}/>
                </LUI.RightBlock>
            </LUI.TopBlock>
            <LUI.List>
                {servicesElements.map((elem, index) =>
                    <li key={index}>
                        <img src={elem.image} alt={elem.image}/>
                        <div>
                            <p>{elem.title}</p>
                            <span>{elem.text}</span>
                        </div>
                    </li>
                )}
            </LUI.List>
        </LUI.Container>

    )
}

export default Services
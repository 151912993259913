import styled from "styled-components";

export const Wrapper = styled.div`
    background-image: url('/backgroundImage.png');
    width: 100%;
    height: 800px;
    background-size: cover;
    display: flex;
    justify-content: center;
    @media (max-width: 1024px) {
        height: 580px;
    }
    @media (max-width: 768px) {
        background-image: url('/backgroundImageMobile.png');
    }
    
`
export const Title = styled.div`
    width: 721px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
        margin-top: 126px;
        color:  #FFF;
        font-size: 48px;
        font-weight: 600;
        line-height: 56px;
        text-transform: uppercase;
        margin-bottom: 12px;
    }
    h3 {
        color: #FFF;
        text-align: center;
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
    }
    @media (max-width: 1024px) {
        margin : 0 164px;
        h1 {
            margin-top: 104px;
            font-size: 26px;
            line-height: 36px;
        }
        h3 {
            font-size: 18px;
            line-height: 24px;
        }
    }
    @media (max-width: 768px) {
        margin : 0 20px;
    }
`
export const Items = styled.div`
    width: 100%;
    display: flex;
    padding: 48px 88px;
    justify-content: center;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
    border-bottom: 1px solid #E1E4F1;
    div {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap : 4px;
        width: 400px;
        h4 {
            color: #467948;
            text-align: center;
            font-size: 30px;
            font-weight: 600;
            line-height: 38px;
            text-transform: uppercase;
            text-align: center;
        }
        span {
            color: #32334D;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px; 
            text-align: center;
        }
    }
    @media (max-width: 1024px) {
        padding: 32px 168px;
        gap: 24px;
        h4 {
            font-size: 26px;
            line-height: 30px;
        }
        span {
            font-size: 14px;
            line-height: 20px;
        }
        div {
            width: 100%;
        }
    @media (max-width: 768px) {
        padding: 32px 20px;
        flex-direction: column;
        div {
            width: 100%;
        }
    }
    }
`
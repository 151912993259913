import {RegisterOptions, useFormContext} from 'react-hook-form';
import * as LUI from './styled'

interface IInputProps {
    title : string;
    name : string;
    rules?: RegisterOptions;
    transform?: (text: string | undefined) => string | undefined | number
}

const Input = (props : IInputProps) => {
    const {title,
        name,
        rules = {},
        transform} = props;
    const {register, setValue, formState : {errors}} = useFormContext();
    const transformInput = (text: string | undefined) => {
        if (transform && text) {
            setValue(name, transform(text))
        }
    }
    return (
        <LUI.InputBlock>
            <input
                className={errors.name ? `field error` :  'field'}
                placeholder={title}
                {...register(name, rules)}
                onChange={(e) => {
                    transform && transformInput(e.target.value)
                }}
            />
        </LUI.InputBlock>


    )
}

export default Input
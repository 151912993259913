import styled from "styled-components";

export const Container = styled.div`
    position: absolute;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 16px 88px;
    align-items: center;
    img {
        width: 100px;
        height: 78px;
    }
    @media (max-width: 1024px) {
        padding: 16px 88px;
        img {
            width: 52px;
            height: 40px;
        }
    }
    @media (max-width: 768px) {
        padding: 16px 20px;
    }
`
export const Options = styled.div`
    display: flex;
    align-items: center;
    gap: 56px;
    ul {
        display: flex;
        align-items: flex-start;
        gap: 24px;
        li {
            a{
                color: #FFF;
            }
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
        } 
    }
    button {
        display: flex;
        padding: 8px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 2px;
        background: #FFF;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px; 
        a {
            color: #467948; 
        }
    }
    
    @media (max-width: 1024px) {
        gap: 16px;
        img {
            width: 24px;
            height: 24px;
        }
        img:hover {
            cursor: pointer;
            opacity: 0.5;
        }
        button {
            font-size: 12px;
            line-height: 18px;
        }
    }
`

export const Wrapper = styled.div`
    position: fixed;
    background: rgba(0, 0, 0, 0.30);
    width: 100%;
    height: 100%;
`
export const Menu = styled.div`
    background: #FFF;
    width: 50%;
    height: 100%;
    margin-left: auto;
    @media (max-width: 768px) {
        width: 100%;
    }
`
export const Header = styled.div`
    padding: 16px 88px;
    height: 72px;
    display: flex;
    justify-content: right;
    align-items: center;
    img {
        width: 24px;
        height: 24px;
    }
    img:hover {
        cursor: pointer;
        opacity: 0.5;
    }
    @media(max-width: 768px) {
        padding: 16px 20px;
        justify-content: space-between;
        img:first-child {
            display: flex;
            width: 52px;
            height: 40px;
        }
    }
`
export const MenuContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: calc(100% - 72px);
    padding: 120px 88px;
    @media(max-width: 768px) {
        padding: 80px 20px;
    }
`
export const MenuOptions = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap : 16px;
    ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        li:not(:last-child) {
            border-bottom: 1px solid #DBE0EF;;
        }
        li {
            text-align: center;
            width: 100%;
            padding-top : 16px;
            padding-bottom: 16px;
            a {
                width: 100%;
                color: #000;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px; 
            }
            a:hover {
                cursor: pointer;
                opacity: 0.5;
            }
        }
    }
    button {
        padding: 8px 24px;
        gap: 10px;
        border-radius: 2px;
        background: #467948;
        a {
            color: #FFF;
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
        }
    }
`
export const Contacts = styled.ul`
    display: flex;
    flex-direction: column;
    gap : 24px;
    li { 
        display: flex;
        gap : 6px;
        img {
            width: 24px !important;
            height: 24px !important;
        }
        div {
            color: #000;
            font-size: 16px;
            line-height: 24px;
            p {
                font-weight: 500;
            }
            span {
                font-weight: 400;
            }
        }
    }
`
import * as LUI from './styled'

const Secrets = () => {
    return (
        <LUI.Container>
            <h2>Secrets to Successful Aircraft Parts Consolidation in the UAE</h2>
            <div>
                <p>For Air Sparepart Solution, operating within this intricate ecosystem necessitates adept navigation of multifaceted challenges. Our commitment extends beyond merely supplying aviation parts; we understand the complexities inherent in facilitating seamless transactions across borders. Particularly in the bustling aviation hub of Dubai, where intricate logistics intertwine with stringent regulatory frameworks, our expertise shines.</p>
                <p>As a premier provider of aviation parts and components, Air Sparepart Solution adeptly maneuvers through the intricate intricacies of global trade. From navigating the intricacies of cargo consolidation to orchestrating the seamless grouping of orders, our operations extend far beyond geographical boundaries. This includes the United States of America, where our commitment to excellence transcends borders to ensure our clients receive unparalleled service and support.</p>
            </div>
        </LUI.Container>
    )
}

export default Secrets
import * as LUI from './styled'
import Input from "./components/Input";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import {useState} from "react";
import {CONTACT_ID} from "../../../../utils/constants";
import {useSendMailMutation} from "../../../../api/mailService";

type TInputs = {
    name : string,
    email : string,
    message : string,
}

const Contact = () => {
    const [checked, setChecked] = useState<boolean>(false);
    const formMethods = useForm<TInputs>({
        mode: 'onSubmit',
        shouldUnregister: false
    })
    const { handleSubmit, reset } = formMethods
    const [sendMail, {isLoading, isSuccess}] = useSendMailMutation()
    const handleChecked = () => {
        setChecked((prevState) => !prevState)
    }
    const onSubmit: SubmitHandler<TInputs> = async (data) => {
        checked && await sendMail(data).then(() => reset())
    }
    return (
        <FormProvider {...formMethods}>
            <LUI.Container id={CONTACT_ID}>
                <LUI.Title>
                    <h3>
                        Work with us today!
                    </h3>
                    <img src={'/lineIcon.svg'} alt={'line'}/>
                    <p>
                        We are pleased at the prospect of a potential partnership with you. Kindly complete the application form provided below to elucidate your project needs and objectives comprehensively. Furnish as much detail as feasible to enable us to customize our approach to align with your specific requirements.
                    </p>
                </LUI.Title>
                <LUI.Form onSubmit={handleSubmit(onSubmit)}>
                    <Input
                        title={'Name'}
                        name={'name'}
                        rules={{
                            minLength: 1,
                            maxLength: 255,
                            required: true}}
                        transform={(text: string | undefined) => {
                            if (text) {
                                const formattedText = text
                                    .slice(0, 255);
                                return formattedText
                            }
                            return text
                        }}
                    />
                    <Input
                        title={"E-mail"}
                        name={"email"}
                        rules={{
                            minLength: 5,
                            maxLength: 255,
                            required: true,
                            pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    }}
                    transform={(text: string | undefined) => {
                        if (text) {
                            const formattedText = text
                                .slice(0, 255);
                            return formattedText
                        }
                        return text
                    }}
                />
                <Input
                    title={"Your message"}
                    name={"message"}
                    rules={{
                        minLength: 1,
                        maxLength: 255,
                        required: true
                    }}
                    transform={(text: string | undefined) => {
                        if (text) {
                            const formattedText = text
                                .slice(0, 255);
                            return formattedText
                        }
                        return text
                    }}
                />
                <LUI.Checkbox>
                    <img src={checked ? "/checkBoxFilled.svg" : "/checkBoxEmpty.svg"} onClick={handleChecked} alt={"checkbox"}/>
                    I agree with the
                    <u>Privacy Policy</u>
                </LUI.Checkbox>
                {!isSuccess && !isLoading &&
                    <LUI.Button type={"submit"} >
                        Send
                    </LUI.Button>
                }
                {isLoading &&
                    <LUI.Loading>
                        <img src={"/loadingIcon.png"} alt={"loading"}/>
                    </LUI.Loading>}
                {isSuccess &&
                    <LUI.Success>
                        <img src={"/okIcon.svg"} alt={"ok"}/>
                        The message has been sent
                    </LUI.Success>}
            </LUI.Form>
        </LUI.Container>
        </FormProvider>
    )
}

export default Contact
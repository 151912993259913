import styled from "styled-components";

export const Container = styled.div`
    padding: 72px 88px;
    @media (max-width: 1024px) {
        padding: 60px 165px;
    }
    @media (max-width: 768px) {
        padding: 60px 20px;
    }
`
export const TopBlock = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap : 24px;
    margin-bottom: 48px;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`
export const LeftBlock = styled.div`
    width: 40%;
    h2 {
        color: #000;
        font-size: 30px;
        font-weight: 600;
        line-height: 38px;
        text-transform: uppercase;
        margin-bottom: 24px;
        width: 400px;
    }
    p {
        color: #6B6C82;
        font-size: 18px;
        font-weight: 500;
        line-height: 26px; 
        width: 400px;
        margin-bottom: 60px;
    }
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 1024px) {
        h2 {
            width: auto;
            font-size: 26px;
        }
        p {
            font-size: 16px;
            margin-bottom: 32px;
            width: auto;
        }
    }
    @media (max-width: 768px) {
        width: 100%
    }
`
export const RightBlock = styled.div`
    width: 60%;
    img {
        width: 100%;
    }
    @media(max-width: 1024px) {
        img {
            height: 300px;
            object-fit: cover
        }
    }
    @media (max-width: 768px) {
        width: 100%;
        img {
        height: 215px;
        width: 215px;
        }   
    }
`
export const List = styled.ul`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    li {
        width: 32%;
        display: flex;
        align-items: start;
        gap: 12px;
        img {
            width: 36px;
            height: 36px;
        }
        div {
            display: flex;
            flex-direction: column;
            gap :16px;
            p {
                color: #000;
                font-size: 18px;
                font-weight: 500;
                line-height: 26px;
            }
            span {
                color:  #6B6C82;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
            }
        }
    }
    @media (max-width: 1024px) {
        flex-direction: column;
        li {
            width: 100%;
            gap : 8px;
            img {
                width: 24px;
                height: 24px;
            }
            div {
                gap: 12px;
            }
        }
    }
    @media (max-width: 768px) {

    }
`
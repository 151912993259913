import * as LUI from './styled'
export const numberToFinancialFormat = (
    value?: null | string | number,
    length?: number
): string => {
    if (value) {
        try {
            const newValue = String(value).replace(/[^\d.]/g, '')
            if (length) {
                if (newValue.length > length) {
                    return newValue.replace(/\B(?=(\d{3})+(?!\d))/g, ' ').slice(0, length + 3)
                } else {
                    return newValue.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                }
            } else {
                return newValue.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
            }
        } catch {
            return value.toString()
        }
    } else {
        return ''
    }
}
const MainContent = () => {
    const getDate = Date.now()
    const currentUnix = 1723720699842 // 15.08.2024
    const currentSpareParts = Math.trunc((getDate - currentUnix) / 2629743)
    return (
        <div>
            <LUI.Wrapper>
                <LUI.Title>
                    <h1>We are first and for most</h1>
                    <h3>Premier provider of aviation parts and components, catering to clients across America, Asia, and
                        Dubai.</h3>
                </LUI.Title>
            </LUI.Wrapper>
            <LUI.Items>
                <div><h4>{numberToFinancialFormat(2000 + (currentSpareParts * 30))}</h4><span>spare parts sold worldwide</span></div>
                <div><h4>23</h4><span>of satisfied customers</span></div>
                <div><h4>B2B</h4><span>cooperation opportunities</span></div>
            </LUI.Items>
        </div>

    )
}

export default MainContent

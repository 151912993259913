import * as LUI from './styled'

const Footer = () => {
    return (
        <LUI.Container>
            <LUI.TopBlock>
                <h3>Contacts</h3>
                <ul>
                    <li>
                        <img src={'./personIcon.svg'} alt={'person'}/>
                        <div>
                            <p>General Manager</p>
                            <span>Hasan@AeroSSolution</span>
                        </div>
                    </li>
                    <li>
                        <img src={'./personIcon.svg'} alt={'person'}/>
                        <div>
                            <p>Technical Specialist</p>
                            <span>Tarik@AeroSSolution </span>
                        </div>
                    </li>
                    <li>
                        <img src={'./phoneIcon.svg'} alt={'phone'}/>
                        <div>
                            <p>Tarik Rençber</p>
                            <a href={'tel:+41765289588'}>+41 (76) 528-95-88</a>
                        </div>
                    </li>
                </ul>
            </LUI.TopBlock>
            <LUI.BottomBlock>
                <p>Copyright 2022-2024</p>
                <p>All Rights Reserved</p>
            </LUI.BottomBlock>
        </LUI.Container>

    )
}

export default Footer
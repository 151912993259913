export const listElements = [
    {
        title: 'Prepare documentation',
        text : 'Before you begin the process of grouping orders, you should carefully prepare all the necessary documents. Their list includes customs declarations, quality certificates, invoices and other accompanying documents, which play a key role in the consolidation process. Proper documentation will help avoid unnecessary delays and problems during customs clearance, which, in turn, will ensure the continuity of your business.'
    },
    {
        title: 'Choose a reliable partner',
        text: 'One of the main aspects of successfully consolidating your cargo is, of course, choosing a reliable partner who has experience in working with aircraft parts and is familiar with the peculiarities of customs clearance in the United States. It is important that your partner has all the necessary licenses and certificates, which guarantees his professionalism and reliability.'
    },
    {
        title: 'Ensure cargo safety',
        text : 'Please remember that aircraft parts are extremely valuable and sensitive cargo. During the consolidation process, parts and components must be securely packaged and protected from damage. It is also important to take into account the rules for storing and transporting this type of cargo to ensure its maximum safety and quality.'
   },
    {
        title: 'Keep track of deadlines',
        text : 'Efficiency is the key to successful cargo consolidation. In the aviation industry, time is especially important. It is important to monitor delivery and customs clearance times to avoid delays and fines. Regularly updating information about the status of the cargo will help avoid problems and ensure timely delivery.'
   },
]
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    padding: 48px 88px 0 88px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;
    border-top: 1px solid #E1E4F1;
    @media (max-width: 1024px) {
        padding: 48px 164px 0 164px;
    }
    @media (max-width: 768px) {
        padding: 48px 20px 0px 20px;
    }
`
export const TopBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap :24px;
    h3 {
        color: #000;
        font-size: 30px;
        font-weight: 600;
        line-height: 38px; 
    }
    ul {
        display: flex; 
        align-items: start;
        gap: 48px;
        li {
            img {
                width: 24px;
                height: 24px;
            }
            display: flex;
            gap: 6px;
            p {
                color:  #000;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px; 
            }
            span {
                color: #000;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
            }
            div {
                a{
                    color: #000 !important;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                }
            }
        }
    }
    @media (max-width: 1024px) {
        ul {
            gap: 24px;
        }
    }
    @media (max-width: 768px) {
        ul {
            flex-direction: column;
        }
    }
    
`
export const BottomBlock = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: #000;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    padding: 12px 0;
`
import * as LUI from './styled'
import {listElements} from "./constants";

const SecretsList = () => {
    return (
        <LUI.Container>
            <h5>
                Let us take a closer look at the key strategies and secrets to successful aircraft parts consolidation in the UAE
            </h5>
            <LUI.Blocks>
                <img src={'./aboutUsListImage.png'} alt={'airplane'}/>
                <LUI.List>
                    {listElements.map((elem, index) =>
                        <li key={index}>
                            <LUI.Number><div>{index+1}</div></LUI.Number>
                            <div>
                                <p>{elem.title}</p>
                                <span>{elem.text}</span>
                            </div>
                        </li>
                    )}
                </LUI.List>
            </LUI.Blocks>
        </LUI.Container>
    )
}

export default SecretsList
import styled, {keyframes} from "styled-components";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;


export const Container = styled.div`
    padding: 72px 88px;
    display: flex;
    flex-direction: column;
    gap : 32px;
    align-items: center;
    @media (max-width: 1024px) {
        padding: 32px 164px;
    }
    @media (max-width: 768px) {
        padding: 32px 20px;
    }
`
export const Title = styled.div`
    display: flex;
    width: 616px;
    flex-direction: column;
    gap : 12px;
    align-items: center;
    h3 {
        color: #000;
        text-align: center;
        font-size: 30px;
        font-weight: 600;
        line-height: 38px;
    }
    img {
        width: 60px;
        height: 3px;
    }
    p {
        color: #6B6C82;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px; 
    }
    @media (max-width: 1024px) {
        width: 100%;
    }
    @media (max-width: 768px) {
        
    }
`

export const Checkbox = styled.div`
    display: flex;
    padding: 8px 0;
    align-items: center;
    color: #32334D;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 24px;
    border-bottom: 1.5px solid #B4B4C3;
    img {
        width: 16px;
        height: 16px;
        margin-right: 10px;
    }
    img:hover {
        cursor: pointer;
    }
    u {
        margin-left: 5px;
    }
`

export const Form = styled.form`
    width: 616px;
    @media (max-width: 1024px) {
        width: 100%;
    }
    @media (max-width: 768px) {

    }
`

export const Button = styled.button`
    border-radius: 2px;
    background: #467948;
    padding: 8px 24px;
    color: #FFF;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    width: 100%;
`
export const Loading = styled.div`
    animation: ${rotate} 1s linear infinite;
    width: 24px;
    height: 24px;
    img {
        width: 24px;
        height: 24px;
    }
    
`
export const Success = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    color: #32334D;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px; 
`
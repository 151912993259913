import * as LUI from './styled'
import {ABOUT_US, CONTACT, SERVICES} from "../../../../utils/constants";
import {useState} from "react";

const Header = (props : {isMobile : boolean, isTable : boolean}) => {
    const {isMobile, isTable} = props
    const [open, setOpen] = useState(false)
    const handleSwitch = () => setOpen(prevState => !prevState)
    return (
        <>
            <LUI.Container>
                <img src={'./logoHeader.svg'} alt={'logo'}/>
                <LUI.Options>
                    {!isTable &&
                        <ul>
                            <li><a href={SERVICES}>services</a></li>
                            <li><a href={ABOUT_US}>about us</a></li>
                            <li><a href={CONTACT}>contacts</a></li>
                        </ul>
                    }
                    <button>
                        <a href={CONTACT}>{isTable ? 'work with us' : 'ask us a question'}</a>
                    </button>
                    {isTable && <img src={'/menuIcon.svg'} alt={'menu'} onClick={handleSwitch}/>}
                </LUI.Options>
            </LUI.Container>
            {open &&
                <LUI.Wrapper>
                    <LUI.Menu>
                        <LUI.Header>
                            {isMobile && <img src={'/menuLogoIcon.svg'} alt={'logo'}/>}
                            <img src={'/crossIcon.svg'} alt={'cross'} onClick={handleSwitch}/>
                        </LUI.Header>
                        <LUI.MenuContent>
                            <LUI.MenuOptions>
                                <ul>
                                    <li><a href={SERVICES} onClick={handleSwitch}>services</a></li>
                                    <li><a href={ABOUT_US} onClick={handleSwitch}>about us</a></li>
                                    <li><a href={CONTACT} onClick={handleSwitch}>contacts</a></li>
                                </ul>
                                <button>
                                    <a href={CONTACT} onClick={handleSwitch}>work with us</a>
                                </button>
                            </LUI.MenuOptions>
                            <LUI.Contacts>
                                <li>
                                    <img src={'./personIcon.svg'} alt={'person'}/>
                                    <div>
                                        <p>General Manager</p>
                                        <span>Hasan@AeroSSolution</span>
                                    </div>
                                </li>
                                <li>
                                    <img src={'./personIcon.svg'} alt={'person'}/>
                                    <div>
                                        <p>Technical Specialist</p>
                                        <span>Tarik@AeroSSolution </span>
                                    </div>
                                </li>
                                <li>
                                    <img src={'./phoneIcon.svg'} alt={'phone'}/>
                                    <div>
                                        <span>+971 50 695 2695</span>
                                    </div>
                                </li>
                            </LUI.Contacts>
                        </LUI.MenuContent>
                    </LUI.Menu>
                </LUI.Wrapper>

            }
        </>
    )
}

export default Header
import styled from "styled-components";

export const Container = styled.div`
    align-items: center;
    display: flex;
    padding: 48px 88px;
    flex-direction: column;
    gap: 32px;
    background: #F4F7FA;
    h2 {
        color: #000;
        text-align: center;
        font-size: 30px;
        font-weight: 600;
        line-height: 38px;
    }
    div {
        display: flex;
        gap: 32px;
        color: #32334D;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }
    @media (max-width: 1024px) {
        padding: 32px 164px;
        gap : 24px;
        h2 {
            font-size: 26px;
        }
        div {
            flex-direction: column;
            gap: 12px;
            font-size: 14px;
        }
    }
    @media (max-width: 768px) {
        padding: 32px 20px;
    }
`
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 0;
    gap : 24px;
    h5 {
        color: #50AC74;
        font-size: 18px;
        font-weight: 500;
        line-height: 26px; 
    }
    @media (max-width: 1024px) {
        padding: 32px 164px;
        h5 {
            margin-bottom: 24px;
            font-size: 16px;
        }
    }
    @media (max-width: 768px) {
        padding: 0;
        h5 {
            padding: 32px 20px 24px;
        }
    }
`
export const Blocks = styled.div`
    display: flex;
    align-items: center;
    img {
        width: 50%;
    }
    @media (max-width: 1024px) {
        flex-direction: column;
        img {
            width: 100%;
        }
    }
    @media (max-width: 768px) {
        img {
            width: 100%;
            height: auto;
        }
    }
`
export const List = styled.ul`
    width: 50%;
    padding-left: 32px;
    padding-right: 88px;
    display: flex;
    flex-direction: column;
    gap : 16px;
    li {
        display: flex;
        gap : 16px;
        div {
            display: flex;
            flex-direction: column;
            gap : 8px;
            p {
                color: #000;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px; 
            }
            span {
                color: #6B6C82;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px; 
            }
        }
    }
    @media (max-width: 1024px) {
        width: 100%;
        margin-top: 24px;
        padding: 0;
        li {
            gap : 12px;
            div {
                display: flex;
                flex-direction: column;
                gap : 4px;
            }
        }
    }
    @media (max-width: 768px) {
        padding: 32px 20px;
    }
`
export const Number = styled.div`
    div {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        background: #50AC74;
        font-weight: 500;
        line-height: 24px;
        font-size: 16px;
        height: 40px;
        width: 40px;
        color: #FFF;
    }
    @media (max-width: 1024px) {
        div {
            height: 30px;
            width: 30px;
            font-size: 14px;
            line-height: 20px;  
        }
    }
`
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    padding: 48px 88px;
    gap: 32px;
    background: #F4F7FA;
    p {
        width: 50%;
        color: #32334D;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px; 
    }
    @media (max-width: 1024px) {
        padding: 32px 164px;
        flex-direction: column;
        gap: 12px;
        p {
            width: 100%; 
        }
    }
    @media (max-width: 768px) {
        padding: 32px 20px;
    }
`
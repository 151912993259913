export const servicesElements = [
    {
        image : '/GlobalShippingAndLogisticsIcon.svg',
        title : 'Global Shipping and Logistics',
        text : 'Regardless of your location worldwide, we ensure the swift and secure delivery of your aviation parts. Our global shipping and logistics capabilities, with a particular emphasis on efficient service to Dubai and other major hubs, guarantee timely delivery to destinations across America, Asia, and beyond.'
    },
    {
        image : '/PartsSourcingAndProcurementIcon.svg',
        title : 'Parts Sourcing and Procurement',
        text : 'Are you in need of a specific aviation part or component? Allow us to manage the sourcing and procurement process on your behalf. Leveraging our extensive network of suppliers, we are able to locate even the most elusive parts with speed and efficiency.'
    },
    {
        image : '/CustomSolutionsIcon.svg',
        title : 'Custom Solutions',
        text : 'Every aviation operation possesses its own unique requirements. Accordingly, we offer bespoke solutions tailored to your specific needs. Whether it involves sourcing rare components or developing specialized inventory management systems, we are equipped to provide comprehensive support.'
    },
    {
        image : '/InventoryManagementIcon.svg',
        title : 'Inventory Management',
        text : 'Managing aviation parts inventory can be a complex task. Simplify your operations with our comprehensive inventory management services. We provide optimization of stock levels, tracking of usage patterns, and assurance that you always have the necessary parts readily available.'
    },
    {
        image : '/TechnicalSupportAndConsultationIcon.svg',
        title : 'Technical Support and Consultation',
        text : 'Do you have questions regarding installation, maintenance, or compatibility? Our team of seasoned aviation professionals is available to offer technical support and consultation services, ensuring that you maximize the utility and performance of your parts.'
    },
    {
        image : '/TrainingAndEducationIcon.svg',
        title : 'Training and Education',
        text : 'Stay at the forefront of industry advancements with our comprehensive training and education programs. From product seminars to certification courses, we provide the resources necessary to keep you informed and empowered in the continuously evolving field of aviation technology.'
    },
]